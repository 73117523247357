<template>
  <layout-view
      :navTitle="tabIndex===0 ? 'Главные категории' : 'Фильтры'"
      :navLoading="serviceCatRootsState.isLoading"
  >
    <template v-slot:navBarActions>
      <div v-if="tabIndex===0">
        <v-btn small depressed class="mt-n1" @click="serviceCatCreateUpdateState.modalOpen()">Создать</v-btn>
      </div>
    </template>
    <template v-slot:nav>
      <div v-if="tabIndex===0">
        <com-branch-autocomplete-multiple class="mx-4 mt-1" global-state :select.sync="comBranchSelect"/>
        <v-row no-gutters class="px-4">
          <v-col class="caption">Список</v-col>
        </v-row>
        <v-list dense>
          <v-list-item-group v-model="menuIndex">
            <v-list-item v-for="(item, i) in (comBranchSelect.length>0 ? serviceCatRoots.filter(v=>v.comBranch===null || comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : serviceCatRoots)" :key="i" :class="{_priceDeleted: item.status==='deleted'}" @click="serviceCatOpen(item.id)">
              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action-text> 
                <tooltip v-if="!$tools.isEmpty(item.comBranch)" right>              
                  <v-avatar class="mr-2" :color="item.comBranch.color" size="10" style="margin-top:-1px"/>
                  <template v-slot:content>
                    {{item.comBranch.name}}
                  </template>
                </tooltip>
              </v-list-item-action-text>
              <v-list-item-action-text>
                {{item.sort}}
              </v-list-item-action-text>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <alert
            v-if="serviceCatRootsState.isSuccess && (comBranchSelect.length>0 ? serviceCatRoots.filter(v=>v.comBranch===null || comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : serviceCatRoots).length===0"
        >
          Не найдено
        </alert>
      </div>
      <div v-if="tabIndex===1" class="px-4">
        <com-branch-autocomplete-multiple class="mt-1" global-state :select.sync="comBranchSelect"/>
        <v-sheet v-if="serviceCatOpenData!==null" class="d-inline-block">
          <v-checkbox v-model="search.inServiceCat" @change="searchAction()" :label="'Искать только в '+serviceCatOpenData.name" dense hide-details="auto"/>
        </v-sheet>
        <v-sheet class="d-inline-block">
          <v-checkbox v-model="search.inServiceCatProgram" @change="searchAction()" label="Искать в программах" class="mt-2" dense hide-details="auto"/>
        </v-sheet>
        <v-sheet class="d-inline-block">
          <v-checkbox v-model="search.service" @change="searchAction()" label="Искать услуги" class="mt-2" dense hide-details="auto"/>
        </v-sheet>
        <v-sheet class="d-inline-block">
          <v-checkbox v-model="search.serviceCat" @change="searchAction()" label="Искать категории" class="mt-2" dense hide-details="auto"/>
        </v-sheet>
      </div>
    </template>
    <template v-slot:tabs>
      <v-row no-gutters class="align-center">
        <v-col class="d-flex align-center">
          <v-tabs v-model="tabIndex" background-color="transparent">
            <v-tab>Категории</v-tab>
            <v-tab>Поиск</v-tab>
          </v-tabs>
          <div>
           

            <v-menu
              v-model="priceDownloadMenu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed small light class="mr-2" v-bind="attrs" v-on="on"><v-icon small class="mr-2">mdi-microsoft-excel</v-icon>Скачать</v-btn>
              </template>
              
              <v-card>
                <v-list dense>
                  <v-list-item
                      @click="priceDownloadMenu = false;"
                      href="/api/priceDownload/formatted/"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Форматированный для печати</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      @click="priceDownloadMenu = false;"
                      href="/api/priceDownload/unformatted/"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Неформатированный</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      @click="priceDownloadMenu = false;"
                      href="/api/priceDownload/prodoctorov/"
                    >
                      <v-list-item-content>
                        <v-list-item-title>Для продокторов</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="auto">
          <div v-if="selected.length>0 || selectedCopy.length>0 || selectedMove.length>0" class="d-inline-block">
            <div v-if="selected.length>0" class="d-inline-block">
              <v-btn depressed small light class="mr-2" @click="selectedDetailState.modalOpen(null, false);userspecSelect=[]">Выделено: {{selected.length}}<v-icon small class="ml-2">mdi-menu-down</v-icon></v-btn>
              <v-btn depressed small light class="mr-2" @click="selectedCopy=selected;selected=[]"><v-icon small class="mr-2">mdi-content-copy</v-icon>Скопировать</v-btn>
              <v-btn depressed small light class="mr-2" @click="selectedMove=selected;selected=[]"><v-icon small class="mr-2">mdi-content-cut</v-icon>Переместить</v-btn>
              <v-btn depressed small light class="mr-2" @click="selectedPriceLock()"><v-icon small class="mr-2">mdi-cash-lock</v-icon>Скидка запрещена / разрешена</v-btn>
              <v-btn depressed small light class="mr-2" @click="selectedDeleteState.modalOpen()"><v-icon small class="mr-2">mdi-delete-outline</v-icon>Удалить</v-btn>
              <v-btn v-if="showDeleted" depressed small light class="mr-2" @click="selectedRestoreState.modalOpen()"><v-icon small class="mr-2">mdi-restore</v-icon>Восстановить</v-btn>         
              <btn-small-accept btnClass="mr-2" name="Вернуть исходную цену" text="Вернуть исходную цену?" color="light" icon="mdi-redo-variant" @accept="restoreDefaultPrice()"/>

             
            </div>
            <div v-if="selectedCopy.length>0 || selectedMove.length>0" class="d-inline-block">
              <!-- <v-btn :disabled="tabIndex===1" depressed small light class="mr-2" @click="selectCopyMove"><v-icon small class="mr-2">mdi-content-paste</v-icon>Вставить</v-btn> -->

              <btn-question  class="mr-2" title="Укажите количество услуг" :width="800" light>
                <template v-slot:default><v-icon small class="mr-2">mdi-content-paste</v-icon> Вставить</template>
                <template v-slot:content>
                  <v-data-table
                      :headers="selectedCopyHeaders"
                      :items="selectedCopy"
                      hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{item.id}}</td>
                        <td>{{item.name}}</td>
                        <td>
                          <service-code-view :code="item.code"/>
                        </td>
                        <td class="text-right">{{$tools.price(item.priceTotal)}}</td>
                        <td class="text-right">
                          <v-text-field v-if="typeof item.serviceCatServiceCount == 'undefined'"
                            :value="1"
                            @input="selectedCopyAmount(item, $event)"
                            outlined dense hide-details
                            type="number"
                            :rules="numberRules"
                          ></v-text-field>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
                <template v-slot:footer>
                  <v-btn text @click="isOpen=false;selectedCopy=[];selectedMove=[];selected=[]">Отменить</v-btn>
                  <v-spacer/>
                  <v-btn text @click="isOpen=false;selectCopyMove();" color="primary">Вставить</v-btn>
                </template>
              </btn-question>
            </div>
            <v-btn depressed small light @click="selectedCopy=[];selectedMove=[];selected=[]" class="mr-2"><v-icon small class="mr-2">mdi-close</v-icon>Отменить</v-btn>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:content="{height}">
      <div v-if="tabIndex===0">
        <div v-if="menuIndex===-1">
          <alert>Выберите главную категорию</alert>
        </div>
        <div v-else-if="serviceCatRootsState.isSuccess && serviceCatRoots.length>0">
          <v-row no-gutters>
            <v-col>
              <div class="subtitle-2 pl-4 pt-4">Просмотр</div>
            </v-col>
            <v-col cols="auto d-flex align-center">
              <div class="show-deleted-control mt-3">
                <v-checkbox
                  v-model="showDeleted"
                  label="Показать удалённые"
                  hide-details
                  dense
                />
              </div>
              <dot class="mt-3"/>
              <v-btn small depressed class="mt-3 mr-2" @click="serviceCatCreateUpdateState.modalOpen({serviceCatId:serviceCatOpenData.id})">Создать категорию</v-btn>
              <v-btn small depressed class="mt-3 mr-2" @click="serviceCatCreateUpdateState.modalOpen(serviceCatOpenData)">Изменить категорию</v-btn>
              <v-btn v-if="serviceCatOpenData!=null&&serviceCatOpenData.status === 'active'" small depressed class="mt-3" @click="serviceCatDeleteState.modalOpen(serviceCatOpenData)">Удалить категорию</v-btn>
              <v-btn v-if="serviceCatOpenData!=null&&serviceCatOpenData.status === 'deleted'" small depressed class="mt-3" @click="serviceCatRestoreState.modalOpen(serviceCatOpenData)">Восстановить</v-btn>
              <dot class="mt-3"/>
              <v-btn small depressed class="mt-3 mr-4" @click="serviceCreateUpdateState.modalOpen({serviceCatId:serviceCatOpenData.id})">Создать услугу</v-btn>
            </v-col>
            <v-col cols="12">
              <v-breadcrumbs class="pt-2 pl-4 pb-2">
                <div v-for="(bread, i) in breadItems" :key="i">
                  <v-breadcrumbs-item :class="'caption font-weight-medium '+(i<breadItems.length-1 ? 'grey--text clickable' : '')" @click="(i<breadItems.length-1 ? serviceCatOpen(bread.id) : null)">

                    <tooltip v-if="bread.comBranch!==null" right>              
                      <v-avatar class="mr-2" :color="bread.comBranch.color" size="10" style="margin-top:-1px"/> 
                      <template v-slot:content>
                        {{bread.comBranch.name}}
                      </template>
                    </tooltip>

                    <chip v-if="bread.isProgram===true" color="green" small dark class="mr-2">Программа</chip>
                    <span>{{bread.text}}</span>
                  </v-breadcrumbs-item>
                  <v-breadcrumbs-divider v-if="i<breadItems.length-1">/</v-breadcrumbs-divider>
                </div>
              </v-breadcrumbs>
            </v-col>
          </v-row>
          <v-data-table
              class="tableMultipleSelect"
              dense
              :headers="headers"
              :items="serviceCatOpenDataItems"
              :loading="serviceCatOpenState.isLoading"
              :footer-props="{'items-per-page-options': [5, 25, 50, 100]}"
              :options.sync="options"
              :items-per-page="25"
              sort-by="sort"
              show-select
              fixed-header
              :server-items-length="serviceCatOpenDataItems.filter(item=>item.id!=='back'&&item.id!==' _back'&&item.id!=='Яback').length"
              :height="height-(18*7)-1"
          >
            <template v-slot:header.data-table-select="">
              <v-checkbox v-model="selectedAll" :indeterminate="selectedAllIndeterminate" class="ma-0 pa-0" dense hide-details />
            </template>
            <template v-slot:item="{ item }">
              <tr
                  v-if="item.id==='back'||item.id===' _back'||item.id==='Яback'"
                  @click="serviceCatOpen(item.serviceCatId)" class="clickable"
              >
                <td colspan="1"></td>
                <td colspan="15">
                  <chip color="green" dark>
                    <v-icon small color="white">mdi-arrow-left</v-icon>
                  </chip>
                </td>
              </tr>
              <tr
                  v-else-if="typeof item.isProgram!=='undefined'"
                  @click="serviceCatOpen(item.id)"
                  @contextmenu.prevent="serviceCatCreateUpdateState.modalOpen(item)"
                  class="clickable"
                  :class="{_priceDeleted: item.status==='deleted'}"
              >
                <td>
                  <v-checkbox @click.stop="selectedAddRemove(item)" :input-value="isSelected(item)" class="ma-0 pa-0" dense hide-details />
                </td>
                <td>{{item.id}}</td>
                <td>
                  <div class="d-inline-flex align-center">
                    <v-icon small class="mr-2">mdi-folder-outline</v-icon>
                    <tooltip v-if="!$tools.isEmpty(item.comBranch)" right>              
                      <v-avatar class="mr-2" :color="item.comBranch.color" size="10" style="margin-top:-1px"/>
                      <template v-slot:content>
                        {{item.comBranch.name}}
                      </template>
                    </tooltip>
                    <chip v-if="item.isProgram" color="green" dark small class="mr-2">Программа</chip>
                  </div>                 
                  <span>{{item.name}}</span>
                </td>
                <td class="text-no-wrap text-right">{{ serviceCatIsInProgram() || item.isProgram ? $tools.price(item.price) : ''}}</td>
                <td>
                  <span v-html="formatDirections(item.directions)"></span>
                </td>
                <td>
                  -
                </td>
                <td>

                  <tooltip v-if="item.isShowForClient" right>              
                    <v-icon small color="grey" class="mr-2">mdi-eye</v-icon>
                    <template v-slot:content>
                      Отображать на сервисах (сайт, моб. приложение)
                    </template>
                  </tooltip>

                  <tooltip v-if="item.isVisitCreateForClient" right>              
                    <v-icon small color="grey" class="mr-2">mdi-account-check</v-icon>
                    <template v-slot:content>
                      Возможность самозаписи клиентом
                    </template>
                  </tooltip>

                  <tooltip v-if="item.isPriceLock" right>              
                    <v-icon small color="grey" class="mr-2">mdi-cash-lock</v-icon>
                    <template v-slot:content>
                      Скидка запрещена
                    </template>
                  </tooltip>
  
                </td>
                <td>{{item.sort}}</td>
                <td>{{item.serviceCatServiceCount}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                  v-else
                  class="clickable"
                  @click="serviceCreateUpdateState.modalOpen(item)"
                  @contextmenu.prevent="serviceCreateUpdateState.modalOpen(item)"
                  :class="{_priceDeleted: item.status==='deleted'}"
              >
                <td>
                  <v-checkbox @click.stop="selectedAddRemove(item)" :input-value="isSelected(item)" class="ma-0 pa-0" dense hide-details />
                </td>
                <td>{{item.id}}</td>
                <td class="text-wrap">

                  <tooltip v-if="!$tools.isEmpty(item.comBranch)" right>              
                    <v-avatar class="mr-2" :color="item.comBranch.color" size="10" style="margin-top:-1px"/> 
                    <template v-slot:content>
                     {{item.comBranch.name}}
                    </template>
                  </tooltip>

                  <tooltip v-if="item.type==='serviceComposite'" right>              
                    <v-icon class="mr-2" size="18" style="margin-top:-1px">mdi-label-multiple-outline</v-icon>
                    <template v-slot:content>
                      Составная услуг
                    </template>
                  </tooltip>

                  <tooltip v-if="item.type==='product'" right>              
                    <v-icon class="mr-2" size="16">mdi-basket-outline</v-icon>
                    <template v-slot:content>
                      Товар
                    </template>
                  </tooltip>

                  {{item.name}}
                </td>
                <td class="text-no-wrap text-right">{{$tools.price(item.priceTotal)}}</td>
                <td>
                  <span v-html="formatDirections(item.directions)"></span>
                </td>
                <td>
                  <span v-html="formatLab(item.serviceLabItem)"></span>
                </td>
                <td>
                  <tooltip v-if="item.isShowForClient" right>              
                    <v-icon small color="grey" class="mr-2">mdi-eye</v-icon>
                    <template v-slot:content>
                      Отображать на сервисах (сайт, моб. приложение)
                    </template>
                  </tooltip>

                  <tooltip v-if="item.isVisitCreateForClient" right>              
                    <v-icon small color="grey" class="mr-2">mdi-account-check</v-icon>
                    <template v-slot:content>
                      Возможность самозаписи клиентом
                    </template>
                  </tooltip>

                  <tooltip v-if="item.isPriceLock" right>              
                    <v-icon small color="grey" class="mr-2">mdi-cash-lock</v-icon>
                    <template v-slot:content>
                      Скидка запрещена
                    </template>
                  </tooltip>

                </td>
                <td>{{item.sort}}</td>
                <td></td>
                <td>{{item.isid}}</td>
                <td>
                  <service-code-view :code="item.code"/>
                </td>
                <td class="text-right">{{item.duration}} мин.</td>
                <td class="text-right">{{item.resultDuration}} д.</td>
                <td class="text-right">{{item.startDay==null ? '-' : item.startDay+'д.'}} {{item.endDay==null ? '' : ' / ' + item.endDay+'д.'}}</td>                   
                <td>
                    {{item.updated ? $tools.dateTimeFormat(item.updated, 'DD.MM.YYYY') : '-'}}
                </td>
                <td>
                    {{item.created ? $tools.dateTimeFormat(item.created, 'DD.MM.YYYY') : '-'}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
      <div v-show="tabIndex===1">
        <v-text-field
            v-model="searchText"
            @keyup="$tools.throttle(()=>searchAction())" 
            @input="$tools.throttle(()=>searchAction())"
            label=""
            append-icon="mdi-close"
            @click:append="searchText=''"
            ref="searchInput"
            outlined dense hide-details="auto">
          <template v-slot:prepend-inner>
            <v-sheet width="24" height="24" class="d-flex align-center justify-center">
              <v-progress-circular v-if="searchState.isLoading" color="primary" size="16" width="2" indeterminate/>
              <v-icon v-else color="primary">mdi-magnify</v-icon>
            </v-sheet>
          </template>
        </v-text-field>
        <v-sheet :max-height="height-56" class="overflow-auto">
          <div v-if="searchText.length>0">
            <alert v-if="searchState.isSuccess && (comBranchSelect.length>0 ? searchResults.filter(v=>v.comBranch===null || comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : searchResults).length===0">Не найдено</alert>
            <v-expansion-panels accordion v-model="searchResultsCatSelects" multiple class="fill-height">
              <v-expansion-panel v-for="(searchResult, index) in (comBranchSelect.length>0 ? searchResults.filter(v=>v.comBranch===null || comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : searchResults)" :key="'searchResult'+index">
                <v-expansion-panel-header class="pa-4">
                  <v-breadcrumbs class="pa-0">
                    <div v-for="(bread, i) in searchResult.breads" :key="i">
                      <v-breadcrumbs-item class="body-2 font-weight-medium">

                        <tooltip v-if="bread.comBranch!==null" right>              
                          <v-avatar class="mr-2" :color="bread.comBranch.color" size="10" style="margin-top:-1px"/>    
                          <template v-slot:content>
                            {{bread.comBranch.name}}
                          </template>
                        </tooltip>

                        <chip v-if="bread.isProgram" color="green" dark small class="mr-2">Программа</chip>
                        <span>{{bread.text}}</span>
                      </v-breadcrumbs-item>
                      <v-breadcrumbs-divider v-if="i<searchResult.breads.length-1">/</v-breadcrumbs-divider>
                    </div>
                  </v-breadcrumbs>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="pa-0 mx-n6">
                    <v-btn @click="serviceCatOpen(searchResult.id);tabIndex=0" small depressed class="ml-4 mb-2">Открыть категорию</v-btn>
                    <v-btn @click="serviceCatCreateUpdateState.modalOpen(searchResult)" small depressed class="ml-2 mb-2">Изменить категорию</v-btn>
                    <v-list class="pa-0" dense>
                      <v-list-item
                          v-for="(item,index) in searchResult.items" :key="'service'+item.id"
                          @click="
                            typeof item.isProgram!=='undefined' ?
                            serviceCatOpen(item.id) :
                            serviceCreateUpdateState.modalOpen(item);
                            typeof item.isProgram!=='undefined' ? tabIndex=0 :''
                          "
                          @contextmenu.prevent="
                            typeof item.isProgram!=='undefined' ?
                            serviceCatCreateUpdateState.modalOpen(item) :
                            serviceCreateUpdateState.modalOpen(item)
                          "
                          class="position-relative"
                      >
                        <v-list-item-content>
                          <v-row class="body-2 small align-center">
                            <v-col cols="auto" class="pl-0">
                              <v-checkbox @click.stop="selectedAddRemove(item)" :input-value="isSelected(item)" class="ma-0 py-0 pl-5 pr-0" dense hide-details />
                            </v-col>
                            <v-col cols="1">
                              <v-sheet min-width="40">{{item.id}}</v-sheet>
                            </v-col>
                            <v-col cols="4">
    
                              <tooltip v-if="!$tools.isEmpty(item.comBranch)" right>              
                                <v-avatar class="mr-2" :color="item.comBranch.color" size="10" style="margin-top:-1px"/>  
                                <template v-slot:content>
                                  {{ item.comBranch.name }}
                                </template>
                              </tooltip>

                              <chip v-if="item.isProgram" color="green" dark small class="mr-2">Программа</chip>
                              <v-icon v-if="typeof item.price!=='undefined'" small class="mr-2">mdi-folder-outline</v-icon>
                  
                              <tooltip v-if="item.type==='serviceComposite'" right>              
                                <v-icon class="mr-2" size="18" style="margin-top:-1px">mdi-label-multiple-outline</v-icon>
                                <template v-slot:content>
                                  Составная услуга
                                </template>
                              </tooltip>

                              <tooltip v-if="item.type==='product'" right>              
                                <v-icon class="mr-2" size="16">mdi-basket-outline</v-icon>
                                <template v-slot:content>
                                  Товар
                                </template>
                              </tooltip>

                              {{item.name}}
                     
                              <tooltip v-if="!$tools.isEmpty(item.comment)" bottom>              
                                <v-icon class="ml-3" small>mdi-comment-text-outline</v-icon> {{$tools.truncateText(item.comment,30)}}
                                <template v-slot:content>
                                  <vue-markdown>{{item.comment}}</vue-markdown>
                                </template>
                              </tooltip>
                            </v-col>
                            <v-col cols="1" style="width:100px">

                              <tooltip v-if="item.isShowForClient" right>              
                                <v-icon small color="grey" class="mr-2">mdi-eye</v-icon>
                                <template v-slot:content>
                                  Отображать на сервисах (сайт, моб. приложение)
                                </template>
                              </tooltip>

                              <tooltip v-if="item.isVisitCreateForClient" right>              
                                <v-icon small color="grey" class="mr-2">mdi-account-check</v-icon>
                                <template v-slot:content>
                                  Возможность самозаписи клиентом
                                </template>
                              </tooltip>

                              <tooltip v-if="item.isPriceLock" right>              
                                <v-icon small color="grey" class="mr-2">mdi-cash-lock</v-icon>
                                <template v-slot:content>
                                  Скидка запрещена
                                </template>
                              </tooltip>

                            </v-col>
                            <v-col cols="auto" style="width:150px">
                              <service-code-view :code="item.code"/>
                            </v-col>
                            <v-col cols="1" title="Лаборатория">
                              <span v-html="typeof item.serviceLabItem!=='undefined' ? 'Лаб: '+  formatLab(item.serviceLabItem) : ''"></span>
                            </v-col>
                            <v-col cols="1" title="Длительность">
                              Длит.: {{item.duration }} мин.
                            </v-col>
                            <v-col cols="1" title="Срок исполнения">Срок исп.: {{item.resultDuration}} д.</v-col>
                            <v-col cols="1" class="text-right" style="min-width:120px">
                              <span v-if="typeof item.price!=='undefined'">{{$tools.price(item.price)}}</span>
                              <span v-else>{{$tools.price(item.priceTotal)}}</span>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                        <v-divider v-if="index<searchResult.items.length-1" class="position-absolute full-width ml-n4" style="bottom:0"/>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-sheet>
      </div>
    </template>
    <template v-slot:footer>
      <services-cat :state="serviceCatCreateUpdateState" @success="serviceCatUpdated" />
      <dialog-view
          :open="serviceCatDeleteState.isModalOpen"
          :title="'Удалить категорию '+(serviceCatDeleteState.modalData!==null ? serviceCatDeleteState.modalData.name+'#'+serviceCatDeleteState.modalData.id :'')+'?'"
          acceptName="Удалить"
          :content="'Внимание! Нажав кнопку «удалить» вы соглашаетесь на удаление данной категории и всех вложенных в неё категорий и услуг!'"
          @acceptAction="serviceCatDelete()"
          @cancel="serviceCatDeleteState.modalClose()"
          :loading="serviceCatDeleteState.isLoading"
      >
      </dialog-view>

      <dialog-view
          :open="serviceCatRestoreState.isModalOpen"
          :title="'Восстановить категорию '+(serviceCatRestoreState.modalData!==null ? serviceCatRestoreState.modalData.name+'#'+serviceCatRestoreState.modalData.id :'')+'?'"
          acceptName="Восстановить"
          @acceptAction="serviceCatRestore()"
          @cancel="serviceCatRestoreState.modalClose()"
          :loading="serviceCatRestoreState.isLoading"
      >
      </dialog-view>

      
      <service :state="serviceCreateUpdateState" @createdUpdatedDeleted="serviceCreatedUpdatedDeleted" />

      <dialog-view
          :open="selectedDeleteState.isModalOpen"
          title="Удалить выделенное?"
          acceptName="Удалить"
          @acceptAction="selectedDelete()"
          @cancel="selectedDeleteState.modalClose()"
          :loading="selectedDeleteState.isLoading"
      />
      <dialog-view
          :open="selectedRestoreState.isModalOpen"
          title="Восстановить выделенное?"
          acceptName="Восстановить"
          @acceptAction="selectedRestore()"
          @cancel="selectedRestoreState.modalClose()"
          :loading="selectedRestoreState.isLoading"
      />
      <dialog-view
          :open="selectedDetailState.isModalOpen"
          title="Просмотр выделенных элементов"
          @cancel="selectedDetailState.modalClose()"
          content-full-size big
      >
        <template v-slot:content>
          <v-row no-gutters>
            <v-col v-if="selectedDetailState.modalData1" cols="auto">
              <v-sheet width="250">
                <user-select type="spec" class="mx-3" :class="selectedDetailState.modalData1 ? 'mt-3' : ''" @selected="(v)=>userspecSelect=v"/>
              </v-sheet>
            </v-col>
            <v-col>
              <v-simple-table class="pa-0" :class="selectedDetailState.modalData1 ? 'mt-2' : ''" dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th colspan="2">Название</th>
                    <th>Код</th>
                    <th>Длительность</th>
                    <th class="text-no-wrap">Срок исполнения</th>
                    <th class="text-no-wrap">План (с/по)</th>
                    <th>Цена</th>
                    <th class="text-no-wrap" v-if="selectedDetailState.modalData1">Длит. для спец.</th>
                    <th class="text-no-wrap" v-if="selectedDetailState.modalData1">Цена для спец.</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(item, index) in selected.filter(v=>selectedDetailState.modalData1 ? typeof v.isProgram==='undefined' : true)"
                      :key="'selected'+index"
                  >
                    <td
                        @click="
                      typeof item.isProgram!=='undefined' ? serviceCatOpen(item.id) : serviceCreateUpdateState.modalOpen(item);
                      typeof item.isProgram!=='undefined' ? selectedDetailState.modalClose() : ''
                      typeof item.isProgram!=='undefined' ? tabIndex=0 : ''
                    "
                        @contextmenu.prevent="
                      typeof item.isProgram!=='undefined' ? serviceCatCreateUpdateState.modalOpen(item) :
                      serviceCreateUpdateState.modalOpen(item)
                    "
                    >{{item.id}}</td>
                    <td
                        @click="
                      typeof item.isProgram!=='undefined' ? serviceCatOpen(item.id) : serviceCreateUpdateState.modalOpen(item);
                      typeof item.isProgram!=='undefined' ? selectedDetailState.modalClose() : ''
                      typeof item.isProgram!=='undefined' ? tabIndex=0 : ''
                    "
                        @contextmenu.prevent="
                      typeof item.isProgram!=='undefined' ? serviceCatCreateUpdateState.modalOpen(item) :
                      serviceCreateUpdateState.modalOpen(item)
                    "
                        class="text-wrap"
                    >
                      <v-icon v-if="typeof item.isProgram!=='undefined'" small class="mr-2">mdi-folder-outline</v-icon>
                      {{item.name}}
                      <div v-if="!$tools.isEmpty(item.comment)">
                        <v-icon small>mdi-comment-text-outline</v-icon>
                        <div class="caption mt-n5 ml-5">{{item.comment}}</div>
                      </div>
                    </td>
                    <td>

                      <tooltip v-if="item.isShowForClient" right>              
                        <v-icon small color="grey" class="mr-2">mdi-eye</v-icon>
                        <template v-slot:content>
                          Отображать на сервисах (сайт, моб. приложение)
                        </template>
                      </tooltip>

                      <tooltip v-if="item.isVisitCreateForClient" right>              
                        <v-icon small color="grey" class="mr-2">mdi-account-check</v-icon>
                        <template v-slot:content>
                          Возможность самозаписи клиентом
                        </template>
                      </tooltip>
    
                      <tooltip v-if="item.isPriceLock" right>              
                        <v-icon small color="grey" class="mr-2">mdi-cash-lock</v-icon>
                        <template v-slot:content>
                          Скидка запрещена
                        </template>
                      </tooltip>

                    </td>
                    <td>
                      <service-code-view :code="item.code"/>
                    </td>
                    <td>
                      {{typeof item.duration==='undefined' ? '' : item.duration+' мин.'}}
                    </td>
                    <td>
                      {{typeof item.resultDuration==='undefined' ? '' : item.resultDuration+' д.'}}
                    </td>
                    <td>
                      <template v-if="typeof item.startDay !=='undefined'">
                        {{item.startDay==null ? '-' : item.startDay+'д.'}} {{item.endDay==null ? '' : ' / ' + item.endDay+'д.'}}
                      </template>
                    </td>
                    <td class="text-no-wrap text-right">
                      <span v-if="typeof item.priceTotal!=='undefined'">{{$tools.price(item.priceTotal)}}</span>
                    </td>
                    <td v-if="selectedDetailState.modalData1">
                      <v-sheet width="90px">
                        <v-text-field :value="ussDurationValue(item)" @keyup="(v)=>item._duration=v.target._value" hide-details dense outlined background-color="white"/>
                      </v-sheet>
                    </td>
                    <td v-if="selectedDetailState.modalData1">
                      <v-sheet width="90px">
                        <v-text-field :value="ussPriceValue(item)" @keyup="(v)=>item._priceTotal=v.target._value" hide-details dense outlined background-color="white"/>
                      </v-sheet>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="7"><span class="subtitle-2">Итого:</span></td>
                    <td class="text-no-wrap text-right"><span class="subtitle-2">{{$tools.price(selectedTotalPrice, '-')}}</span></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions>
          <v-btn
            text
            @click="selectedDetailState.modalData1=!selectedDetailState.modalData1"
            :disabled="selected.filter(v=>typeof v.isProgram==='undefined').length===0"
          >
            <v-switch
              @click="selectedDetailState.modalData1=!selectedDetailState.modalData1"
              v-model="selectedDetailState.modalData1"
              flat
              color="grey"
              inset
              hide-details
              dense
              class="mt-0 mr-n2"
            />
            Привязка к специалистам
          </v-btn>
          <v-btn
            v-if="selectedDetailState.modalData1"
            @click="userspecServicesAction()"
            :disabled="userspecSelect.length===0"
            :loading="userspecServicesState.isLoading"
            color="primary"
            class="ml-2"
            text
          >Сохранить</v-btn>
        </template>
      </dialog-view>
    </template>
  </layout-view>
</template>

<script>
import State from "@/plugins/state";
import {FormFieldRules} from "@/plugins/formFieldRules";
import axios from "axios";
import Api from "@/Api";
import LayoutView from "@/components/LayoutView";
import ServicesCat from "@/views/Service/ServicesCat";
import Service from "@/views/Service/Service";
import ServiceCodeView from "@/views/Service/ServiceCodeView";
import DialogView from "@/components/DialogView";
import UserSelect from "@/views/User/components/UserSelect.vue";
import BtnQuestion from "@/components/btnQuestion.vue"
import Chip from "@/componentsV2/base/Chip.vue";
import Dot from "@/componentsV2/base/Dot.vue";
import ComBranchAutocompleteMultiple from "@/componentsV2/custom/ComBranchAutocompleteMultiple.vue";
import Alert from "@/componentsV2/base/Alert.vue";
import BtnSmallAccept from "@/componentsV2/base/BtnSmallAccept.vue";
import Tooltip from "@/componentsV2/base/Tooltip.vue";

export default {
  components: {
    Alert,
    ComBranchAutocompleteMultiple,
    Dot,
    Chip,
    UserSelect, DialogView, ServiceCodeView, Service, ServicesCat, LayoutView, BtnQuestion, BtnSmallAccept, Tooltip},
  data: () => ({
    formFieldRules: FormFieldRules,
    tabIndex:0,
    menuIndex:-1,

    comBranchSelect:[],

    serviceCatRootsState: new State(),
    serviceCatRoots: [],

    serviceCatOpenState: new State(),
    serviceCatOpenData:null,
    serviceCatOpenDataItems:[],

    serviceCatCreateUpdateState: new State(),
    serviceCatDeleteState: new State(),
    serviceCatRestoreState: new State(),
    serviceCreateUpdateState: new State(),

    breadItems: [],
    headers: [
      { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
      { text: 'Название', value: 'name', class:'text-wrap', width:500 },
      { text: 'Цена', value: 'price', class:'text-no-wrap', sortable: false },
      { text: 'Направление', value: 'directions', class:'text-no-wrap' },
      { text: 'Лаборатория', value: 'serviceLabItem', class:'text-no-wrap' },
      { text: '', sortable: false},
      { text: 'Сорт.', value: 'sort', class:'text-no-wrap' },
      { text: 'Элем.', value: 'count', class:'text-no-wrap', sortable: false },
      { text: 'Внеш. код', value: 'code', class:'text-no-wrap' },
      { text: 'Код', value: 'code', class:'text-no-wrap' },
      { text: 'Длительность', value: 'duration', class:'text-no-wrap' },
      { text: 'Срок исп.', value: 'resultDuration', class:'text-no-wrap' },
      { text: 'План (с/по)', value: 'startDay', class:'text-no-wrap' },
      { text: 'Изменено', value: 'updated', class:'text-no-wrap' },
      { text: 'Создано', value: 'created', class:'text-no-wrap' },
    ],
    selectedCopyHeaders: [
      { text: 'ID', value: 'id', align: 'start', class:'text-no-wrap'},
      { text: 'Название', value: 'name', class:'text-no-wrap' },
      { text: 'Код', value: 'code', class:'text-no-wrap' },
      { text: 'Цена', value: 'price', class:'text-no-wrap', sortable: false },
      { text: 'Количество', value: 'amount', class:'text-no-wrap', sortable: false },
    ],
    selectedDetailState: new State(),
    selected:[],
    selectedCopy:[],
    selectedMove:[],
    selectedTotalPrice: null,
    selectedDeleteState: new State(),
    selectedRestoreState: new State(),
    selectedPriceLockState: new State(),
    userspecSelect:[],
    userspecServicesState: new State(),

    search:{
      inServiceCat:false,
      inServiceCatProgram:false,
      service:true,
      serviceCat:true,
    },
    searchText:'',
    searchState: new State(),
    searchResults:[],
    searchResultsCatSelects:[],
    options: {},
    sortName: 'sort',
    sortEventAmount: 0,
    sortDirection: 'up',
    showDeleted: false,

    numberRules: [
        (v) => !!v || 'Поле должно быть заполнено',
        (v) => !isNaN(parseFloat(v)) && isFinite(v) || 'Введите число',
        (v) => v <= 100 || 'Число должно быть не более 100'
    ],

    backCallBack:null,
    priceDownloadMenu: false
  }),
  mounted() {
    this.loadServiceCatRoots(null, false);
  },
  beforeMount() {
    this.backCallBack = (event) => {
      if (event.key === 'Escape' && this.serviceCatOpenData!==null && this.serviceCatOpenData.serviceCat!==null) {
        this.serviceCatOpen(this.serviceCatOpenData.serviceCat.id);
      }
    }
    document.addEventListener('keydown', this.backCallBack)
  },
  unmounted() {
    document.removeEventListener('keydown', this.backCallBack)
  },
  watch:{
    tabIndex(){
      if(this.tabIndex===1)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.searchInput.$refs.input.focus();
          })
        });
    },
    // searchText() {
    //   this.searchAction();
    // },
    showDeleted() {
      if (!this.showDeleted&&this.serviceCatOpenData.status === 'deleted') {
        this.loadServiceCatRoots(null, true);
      }
      else {
        this.loadServiceCatRoots(this.serviceCatOpenData.id, false);
      }
    },
    options: {
      handler($event) {
        if(typeof $event.sortBy[0] == 'undefined') {
          this.sortEventAmount = 1
        } 
        else if(this.sortName!=$event.sortBy[0]){
          this.sortName = $event.sortBy[0]
          this.sortEventAmount = 0
          console.log('не равно')
        }
  
        this.sortEventAmount+=1

        if (this.sortEventAmount==1){
          this.sortDirection = 'up'
        }
        if (this.sortEventAmount==2){
          this.sortDirection = 'down'
        }
        if (this.sortEventAmount==3){
          this.sortDirection = 'up'
          this.sortEventAmount = 1
        }


        if (this.serviceCatOpenDataItems.filter(cat=>cat.id == 'back'||cat.id == ' _back'||cat.id == 'Яback').length>0){  
          this.arrowUpFix()
        }
      }
    },
    selected() {
      let totalPrice = 0;
      if(this.selected.length>0){
        this.selected.forEach(s=>{
          if (s.priceTotal)
          totalPrice += parseFloat(s.priceTotal);
        });
      }
      this.selectedTotalPrice = totalPrice;
    }
  },
  computed:{
    selectedAll:{
      get() {
        return this.serviceCatOpenDataItems.length>0 && this.serviceCatOpenDataItems.length===this.selected.length;
      },
      set(v) {
        this.selected = v ? this.serviceCatOpenDataItems.filter(v=>v.id!=='back'&&v.id != ' _back'&&v.id != 'Яback') : [];
      }
    },
    selectedAllIndeterminate:{
      get() {
        return this.selected.length>0 && this.serviceCatOpenDataItems.length!==this.selected.length;
      },
      set() {
        this.selected = this.items.filter(v=>v.id!=='back'&&v.id != ' _back'&&v.id != 'Яback');
      }
    },
    formatDirections(){
      return (directions) =>{
        if (directions.length>0) {
          let arr = [];
          directions.forEach(d=>{
            arr.push(d.name);
          })
          return arr.join(', ');
        } else {
          return '-';
        }
      }
    },
    formatLab(){
      return (serviceLabItem) =>{
        if (serviceLabItem.length>0) {
          let arr = [];
          serviceLabItem.forEach(l=>{
            arr.push(l.labId);
          })
          return arr.join(', ');
        } else {
          return '-';
        }
      }
    }
  },
  methods: {
    loadServiceCatRoots(afterOpenServiceCatId=null, isOpenFirst=false){
      this.serviceCatRootsState.stateLoading()
      // axios.get(Api.host+'/serviceCat/struct/')
      //     .then(r =>{
      //       this.serviceCatRoots = r.data;
      //       this.serviceCatRootsState.stateSuccess();
      //       if(afterOpenServiceCatId!==null)
      //         this.serviceCatOpen(afterOpenServiceCatId);
      //       if(isOpenFirst)
      //         this.serviceCatOpen(this.serviceCatRoots[0].id);
      //     })
      //     .catch(e =>{
      //       Api.responseError(e)
      //       this.serviceCatRootsState.stateError();
      //     });


          axios.all([
            axios.get(Api.host+'/serviceCat/struct/'),
            this.showDeleted ? axios.get(Api.host+'/serviceCat/struct/?status=deleted') : null
          ]).then(axios.spread((result1, result2)=>{
            let r = result1.data

            if (result2 && this.showDeleted === true) {
              r = result1.data.concat(result2.data)
            }

           this.serviceCatRoots = r;
            this.serviceCatRootsState.stateSuccess();
            if(afterOpenServiceCatId!==null)
              this.serviceCatOpen(afterOpenServiceCatId);
            if(isOpenFirst){
              this.serviceCatOpen(this.serviceCatRoots[0].id);
            }

          })).catch(e =>{
            Api.responseError(e)
            this.serviceCatRootsState.stateError();
          });
          
    },
    serviceCatOpen(id){
      this.serviceCatOpenState.stateLoading();

      axios.all([
        axios.get(Api.host+'/serviceCat/'+id+'/struct/'),
        this.showDeleted ? axios.get(Api.host+'/serviceCat/'+id+'/struct/?status=deleted') : null
      ]).then(axios.spread((result1, result2)=>{

        let r = result1.data

        if (result2 && this.showDeleted === true) {
          result1.data.serviceCats = result1.data.serviceCats.concat(result2.data.serviceCats)
          result1.data.services = result1.data.services.concat(result2.data.services)
          r = result1.data
        }

        this.serviceCatOpenData = r;
        this.serviceCatOpenDataItems = [];
        if (this.serviceCatOpenData.serviceCat!=null) {
          this.serviceCatOpenDataItems.push({id:'back', serviceCatId:this.serviceCatOpenData.serviceCat.id});
          this.arrowUpFix()
        }

        this.serviceCatOpenData.serviceCats.forEach((v)=>{
          this.serviceCatOpenDataItems.push(v);
        });

        this.serviceCatOpenData.services.forEach((v)=>{
          this.serviceCatOpenDataItems.push(v);
        });
   
        let serviceCat = this.serviceCatOpenData;

        let menuIndex = null;
        (this.comBranchSelect.length>0 ? this.serviceCatRoots.filter(v=>v.comBranch===null || this.comBranchSelect.some(v1 =>v.comBranch.id===v1.id)) : this.serviceCatRoots).forEach((v,index)=>{
          if(v.id===serviceCat.id) menuIndex=index;
          if(serviceCat.serviceCat!==null){
            if(v.id===serviceCat.serviceCat.id) menuIndex=index;
            if(serviceCat.serviceCat.serviceCat!==null){
              if(v.id===serviceCat.serviceCat.serviceCat.id) menuIndex=index;
              if(serviceCat.serviceCat.serviceCat.serviceCat!==null){
                if(v.id===serviceCat.serviceCat.serviceCat.serviceCat.id) menuIndex=index;
                if(serviceCat.serviceCat.serviceCat.serviceCat.serviceCat!==null){
                  if(v.id===serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id) menuIndex=index;
                }
              }
            }
          }
        });
        if(menuIndex!==null)
          this.menuIndex = menuIndex;

        
        this.breadItems = [];
        if (serviceCat.serviceCat!=null){
          if (serviceCat.serviceCat.serviceCat!=null){
            if (serviceCat.serviceCat.serviceCat.serviceCat!=null){
              if (serviceCat.serviceCat.serviceCat.serviceCat.serviceCat!=null){
                if (serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat!=null){
                  this.breadItems.push({
                    id:serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id,
                    text: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name,
                    isProgram: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                    comBranch: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                    disabled: false,
                  });
                }
                this.breadItems.push({
                  id:serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id,
                  text: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name,
                  isProgram: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                  comBranch: serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                  disabled: false,
                });
              }
              this.breadItems.push({
                id:serviceCat.serviceCat.serviceCat.serviceCat.id,
                text: serviceCat.serviceCat.serviceCat.serviceCat.name,
                isProgram: serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                comBranch: serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                disabled: false,
              });
            }
            this.breadItems.push({
              id:serviceCat.serviceCat.serviceCat.id,
              text: serviceCat.serviceCat.serviceCat.name,
              isProgram: serviceCat.serviceCat.serviceCat.isProgram,
              comBranch: serviceCat.serviceCat.serviceCat.comBranch,
              disabled: false,
            });
          }
          this.breadItems.push({
            id:serviceCat.serviceCat.id,
            text: serviceCat.serviceCat.name,
            isProgram: serviceCat.serviceCat.isProgram,
            comBranch: serviceCat.serviceCat.comBranch,
            disabled: false,
          });
        }
        this.breadItems.push({
          id:serviceCat.id,
          text: serviceCat.name,
          isProgram: serviceCat.isProgram,
          comBranch: serviceCat.comBranch,
          disabled: false,
        });

        this.serviceCatOpenState.stateSuccess();
      })).catch(e =>{
            Api.responseError(e)
            this.serviceCatOpenState.stateError();
      });



    },

    serviceCatUpdated(){
      this.loadServiceCatRoots();
      this.serviceCatOpen(this.serviceCatOpenData.id);
      this.searchAction(true);
    },

    serviceCreatedUpdatedDeleted(){
      if(this.serviceCatOpenData!==null)
        this.serviceCatOpen(this.serviceCatOpenData.id);
      this.searchAction(true);
    },

    serviceCatIsInProgram(){
      let result = this.breadItems.filter((v)=>v.isProgram);
      return result.length>0;
    },

    serviceCatDelete(){
      this.serviceCatDeleteState.stateLoading();
      axios.delete(Api.host+'/serviceCat/'+this.serviceCatDeleteState.modalData.id+'/')
          .then(() =>{
            this.$store.commit('alertSuccess', 'Категория удалена');
            this.serviceCatDeleteState.stateSuccess();
            this.serviceCatDeleteState.modalClose();
            this.loadServiceCatRoots();
            if(this.serviceCatOpenData.serviceCat===null){
              this.loadServiceCatRoots(null, true);
            } else {
              this.serviceCatOpen(this.serviceCatOpenData.serviceCat.id);
            }
          })
          .catch(e =>{
            Api.responseError(e)
            this.dataState.stateError();
          });
    },    
    serviceCatRestore(){
      this.serviceCatRestoreState.stateLoading();

      axios.put(Api.host+'/serviceCat/'+this.serviceCatRestoreState.modalData.id+'/', {status:'active'})
        .then(() =>{
          this.$store.commit('alertSuccess', 'Категория восстановлена');
         //this.serviceCatOpen(this.serviceCatOpenData.id);
          this.loadServiceCatRoots(this.serviceCatOpenData.id, false);
          this.serviceCatRestoreState.stateSuccess();
          this.serviceCatRestoreState.modalClose();
        })
        .catch(e =>{
          Api.responseError(e)
          this.dataState.stateError();
        });
    },    
    selectedAddRemove(item){
      if(!this.isSelected(item)){
        this.selected.push(item);
      } else {
        this.selected = this.selected.filter((v)=>{
          if(v.id===item.id && typeof v.isProgram===typeof item.isProgram)
            return false;
          return true;
        });
      }
    },
    isSelected(item){
      let find = false;
      this.selected.forEach((v)=>{
        if(v.id===item.id && typeof v.isProgram===typeof item.isProgram)
          find = true;
      });
      return find;
    },

    selectCopyMove(){
      if(this.selectedCopy.length>0){
        let arr = {
          to:this.serviceCatOpenData.id,
          serviceCat:[],
          service:[],
        };
        this.selectedCopy.forEach((v)=>{
          if (typeof v.isProgram!=='undefined') {
            arr.serviceCat.push(v.id);
          }
          else {
            if (v.amount&&v.amount>0&&v.amount<101){
              var i = 0;
              while (i < v.amount) {
                arr.service.push(v.id)
                i++;
              }
            } else {
              arr.service.push(v.id)
            }
          }
        });
        this.selectedCopy = [];

        this.selectedDeleteState.stateLoading();
        Api.service.service.copy(arr, ()=>{
          this.$store.commit('alertSuccess', 'Успешно скопировано');
          this.serviceCatOpen(this.serviceCatOpenData.id);
          this.selectedDeleteState.stateSuccess();
          this.selectedDeleteState.modalClose();
        }, (e)=>{
          this.selectedDeleteState.stateError();
          this.selectedDeleteState.modalClose();
          this.$store.commit('alertError', e.response.data.message);
        });
      }
      if(this.selectedMove.length>0){
        let arr = {
          to:this.serviceCatOpenData.id,
          serviceCat:[],
          service:[],
        };
        this.selectedMove.forEach((v)=>{
          if (typeof v.isProgram!=='undefined') arr.serviceCat.push(v.id);
          else arr.service.push(v.id);
        });
        this.selectedMove = [];

        this.selectedDeleteState.stateLoading();
        Api.service.service.move(arr, ()=>{
          this.$store.commit('alertSuccess', 'Успешно перемещено');
          this.serviceCatOpen(this.serviceCatOpenData.id);
          this.selectedDeleteState.stateSuccess();
          this.selectedDeleteState.modalClose();
        }, (e)=>{
          this.selectedDeleteState.stateError();
          this.selectedDeleteState.modalClose();
          this.$store.commit('alertError', e.response.data.message);
        });
      }
    },
    selectedDelete(){
      let arr = {
        serviceCat:[],
        service:[],
      };

      this.selected.forEach((v)=>{
        if (typeof v.isProgram!=='undefined') arr.serviceCat.push(v.id);
        else arr.service.push(v.id);
      });
      this.selected = [];

      this.selectedDeleteState.stateLoading();
      Api.service.service.delete(arr, ()=>{
        this.$store.commit('alertSuccess', 'Успешно удалено');
        this.serviceCatOpen(this.serviceCatOpenData.id);
        this.selectedDeleteState.stateSuccess();
        this.selectedDeleteState.modalClose();
      }, (e)=>{
        this.selectedDeleteState.stateError();
        this.selectedDeleteState.modalClose();
        this.$store.commit('alertError', e.response.data.message);
      });
    },
    selectedRestore(){
      let queries = []

      this.selected.forEach((v)=>{
        if (typeof v.isProgram!=='undefined') queries.push('/serviceCat/'+v.id);
        else queries.push('/service/'+v.id);
      });
      this.selected = [];

      this.selectedRestoreState.stateLoading();
      console.log(queries);

      axios.all(queries.map((query) => axios.put(Api.host+query+'/', {status:'active'}))).then(() => {
          this.serviceCatOpen(this.serviceCatOpenData.id);
          this.selectedRestoreState.stateSuccess();
          this.selectedRestoreState.modalClose();
        }
      ).catch(e =>{
        this.selectedRestoreState.stateError();
        this.selectedRestoreState.modalClose();
        this.$store.commit('alertError', e.response.data.message);
      });
    },   
    selectedPriceLock(){
      this.selectedPriceLockState.stateLoading();
      let arr = [];
      this.selected.forEach(v=>{
        if (typeof v.code!=='undefined')
          arr.push(v.id);
      });
      this.selected = [];

      Api.service.service.servicesPriceLock(arr, ()=>{
        this.selectedPriceLockState.stateSuccess();
        this.$store.commit('alertSuccess', 'Успешно изменено');
        this.serviceCatOpen(this.serviceCatOpenData.id);
      }, (e)=>{
        this.selectedPriceLockState.stateError();
        this.$store.commit('alertError', e.response.data.message);
      });
    },

    searchAction(isRefresh=false){
      if (this.searchText!=null && this.searchText.length>1){
        this.searchState.stateLoading();
        let url = '/api/serviceCat/search/?';
        if (this.search.inServiceCat) url += 'serviceCatId='+this.serviceCatOpenData.id+'&';
        if (this.search.inServiceCatProgram) url += 'isServiceCatProgram=1&';
        if (this.search.service) url += 'isService=1&';
        if (this.search.serviceCat) url += 'isServiceCat=1&';
        url += 'q='+this.searchText;

        axios.get(url)
            .then((response)=>{
              this.searchState.stateSuccess();

              let arr = [];
              response.data.forEach((v1)=>{
                let s = null;
                arr.forEach((v2)=>{
                  if (v2.id===v1.serviceCat.id) s = v2;
                });

                if (s===null){
                  let breads = [];
                  let _breadNames = '';
                  let _hasProgram = false;
                  if (v1.serviceCat != null) {
                    if (v1.serviceCat.serviceCat != null) {
                      if (v1.serviceCat.serviceCat.serviceCat != null) {
                        if (v1.serviceCat.serviceCat.serviceCat.serviceCat != null) {
                          if (v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat != null) {
                            breads.push({
                              id: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.id,
                              text: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name,
                              isProgram: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                              comBranch: v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                              disabled: false,
                            });
                            _breadNames += v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.name;
                            if (v1.serviceCat.serviceCat.serviceCat.serviceCat.serviceCat.isProgram) _hasProgram = true;
                          }
                          breads.push({
                            id: v1.serviceCat.serviceCat.serviceCat.serviceCat.id,
                            text: v1.serviceCat.serviceCat.serviceCat.serviceCat.name,
                            isProgram: v1.serviceCat.serviceCat.serviceCat.serviceCat.isProgram,
                            comBranch: v1.serviceCat.serviceCat.serviceCat.serviceCat.comBranch,
                            disabled: false,
                          });
                          _breadNames += v1.serviceCat.serviceCat.serviceCat.serviceCat.name;
                          if (v1.serviceCat.serviceCat.serviceCat.serviceCat) _hasProgram = true;
                        }
                        breads.push({
                          id: v1.serviceCat.serviceCat.serviceCat.id,
                          text: v1.serviceCat.serviceCat.serviceCat.name,
                          isProgram: v1.serviceCat.serviceCat.serviceCat.isProgram,
                          comBranch: v1.serviceCat.serviceCat.serviceCat.comBranch,
                          disabled: false,
                        });
                        _breadNames += v1.serviceCat.serviceCat.serviceCat.name;
                        if (v1.serviceCat.serviceCat.serviceCat.isProgram) _hasProgram = true;
                      }
                      breads.push({
                        id: v1.serviceCat.serviceCat.id,
                        text: v1.serviceCat.serviceCat.name,
                        isProgram: v1.serviceCat.serviceCat.isProgram,
                        comBranch: v1.serviceCat.serviceCat.comBranch,
                        disabled: false,
                      });
                      _breadNames += v1.serviceCat.serviceCat.name;
                      if (v1.serviceCat.serviceCat.isProgram) _hasProgram = true;
                    }
                    breads.push({
                      id: v1.serviceCat.id,
                      text: v1.serviceCat.name,
                      isProgram: v1.serviceCat.isProgram,
                      comBranch: v1.serviceCat.comBranch,
                      disabled: false,
                    });
                    _breadNames += v1.serviceCat.name;
                    if (v1.serviceCat.isProgram) _hasProgram = true;
                  }

                  arr.push({
                    id:v1.serviceCat.id,
                    name:v1.serviceCat.name,
                    isProgram:v1.serviceCat.isProgram,
                    comBranch: v1.serviceCat.comBranch,
                    breads:breads,
                    _breadNames: _breadNames,
                    _hasProgram: _hasProgram,
                    items:[v1],
                    sort: v1.sort,
                    des:v1.des,
                    isShowForClient: v1.isShowForClient,
                    price: v1.price,
                    priceNew: v1.priceNew,
                  });
                } else {
                  s.items.push(v1);
                }
              });

              arr.forEach((v1)=>{
                v1.items = v1.items.sort((a,b)=>a.name===b.name ? 0 : a.name < b.name ? -1 : 1)
              });

              this.searchResults = arr
                  .filter((v)=>!v._hasProgram)
                  .sort((a, b)=>{
                    if (a._breadNames===b._breadNames) return 0;
                    return a._breadNames>b._breadNames;
                  })
                  .concat(arr
                      .filter((v)=>v._hasProgram)
                      .sort((a, b)=>{
                        if (a._breadNames===b._breadNames) return 0;
                        return a._breadNames>b._breadNames;
                      })
                  );
              if(!isRefresh){
                this.searchResultsCatSelects = [];
                this.searchResults.forEach(()=>{
                  this.searchResultsCatSelects.push(this.searchResultsCatSelects.length);
                })
              }
            })
            .catch(()=>{
              this.searchState.stateError();
            });
      } else {
        if(!isRefresh)
          this.searchResults = [];
      }
    },

    ussDurationValue(v){
      return v.duration;
    },
    ussPriceValue(v){
      return v.priceTotal;
    },

    userspecServicesAction(){
      this.userspecServicesState.stateLoading();

      let userspecs = [];
      this.userspecSelect.forEach(v=>userspecs.push(v.id))
      let services = [];
      this.selected.filter(v=>typeof v.isProgram==='undefined').forEach(v1=>{
        services.push({
          id:v1.id,
          duration:typeof v1._duration==='undefined' ? v1.duration : v1._duration,
          priceTotal:typeof v1._priceTotal==='undefined' ? v1.priceTotal : v1._priceTotal,
        });
      });

      axios.post(Api.host+'/service/user/multiple/', {
        userIds:userspecs,
        services:services,
      })
        .then(()=>{
          this.userspecServicesState.stateSuccess();
          this.selectedDetailState.modalClose();
          this.selected = [];
          this.$store.commit('alertSuccess', 'Успешно привязано');
        })
        .catch(() =>{
          this.userspecServicesState.stateError();
        });
    },
    arrowUpFix(){
      if (this.sortDirection == 'up') {
        this.serviceCatOpenDataItems[0].id=" _back"
        this.serviceCatOpenDataItems[0].name=" 0000"
        this.serviceCatOpenDataItems[0].sort=" 00000"
        this.serviceCatOpenDataItems[0].code=" 0000"
        this.serviceCatOpenDataItems[0].isid=" 0000"
        this.serviceCatOpenDataItems[0].duration=" 0000"
      }
      if (this.sortDirection == 'down') {
        this.serviceCatOpenDataItems[0].id="Яback"
        this.serviceCatOpenDataItems[0].name="ЯЯ"
        this.serviceCatOpenDataItems[0].sort="ЯЯ"
        this.serviceCatOpenDataItems[0].code="ЯЯ"
        this.serviceCatOpenDataItems[0].isid="ЯЯ"
        this.serviceCatOpenDataItems[0].duration="ЯЯ"
      }
    },
    selectedCopyAmount(item,value) {
      this.selectedCopy.forEach(s=>{
        if(s.id === item.id) {
          s.amount = value
        }
      });
    },
    restoreDefaultPrice() {
      let serviceCat = [];
      let service = [];

      this.selected.forEach(s=>{
        if(typeof s.isProgram !== 'undefined'){
          serviceCat.push(s.id);
        } else {
          service.push(s.id);
        }
      });

      Api.service.service.restoreDefaultPrice(serviceCat, service, ()=>{
        this.$store.commit('alertSuccess', 'Цены успешно изменены');
        this.serviceCatUpdated();
      }, (e)=>{
        this.$store.commit('alertError', e.response.data.message);
      });
    },
  }
}
</script>


<style lang="scss" scoped>
.show-deleted-control {
  display: inline-flex;
  .v-input {
    margin: 0;
    padding: 0;
  }
}
</style>